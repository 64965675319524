<template>
  <div class="an-trent">
    <div class="an-trent-select">
      <div class="an-title">
        <i class="el-icon-search"></i>
        <span>筛选条件</span>
      </div>
      <div class="search-con">
        <div class="search-label">监测要素：</div>
        <el-radio-group v-model="form.sensorId" @change="getPointById">
          <el-radio border v-for="(item,index) in checkList1" :key="index" :label="item.id">{{item.name}}</el-radio>
        </el-radio-group>
      </div>
      <div class="search-con">
        <div class="search-label">监测点位：</div>
        <el-radio-group v-model="form.pointId" @change="submit">
          <el-radio border v-for="(item,index) in checkList2" :key="index" :label="item.id">{{item.subName}}</el-radio>
        </el-radio-group>
      </div>
      <div class="search-con">
        <div class="search-label">数据类型：</div>
        <el-radio-group v-model="form.type" @change="submit">
          <el-radio border v-for="(item,index) in checkList3" :key="index" :label="item.value">{{item.name}}</el-radio>
        </el-radio-group>
      </div>
      <div style="display: flex">
        <div class="search-con" style="width: auto">
          <div class="search-label">图表1时间范围：</div>
          <el-date-picker
              v-model="form.time1"
              size="small"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              @change="submit"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="search-con" style="width: auto">
          <div class="search-label">图表2时间范围：</div>
          <el-date-picker
              v-model="form.time2"
              size="small"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              @change="submit"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </div>

    </div>
    <div class="an-trend-page">
      <div class="an-trend-con" v-loading="loading1">
        <chart-three :value="chartData1"></chart-three>
      </div>
      <div class="an-trend-con" v-loading="loading2">
        <chart-three :value="chartData2"></chart-three>
      </div>
    </div>
  </div>
</template>

<script>
  import chartThree from "../chart/chart03"
  export default {
    name: "index",
    components:{
      chartThree
    },
    data(){
      return{
        chartData1:{
          xaxis:[],
          unit:'',
          data:[]
        },
        chartData2:{
          xaxis:[],
          unit:'',
          data:[]
        },
        checkList1:[],
        checkList2:[],
        checkList3:[],
        form:{
          sensorId:0,
          pointId:'',
          type:1,
          time1:[],
          time2:[]
        },
        loading1:false,
        loading2:false
      }
    },
    created() {
      let date = new Date()
      let start = this.$moment(date).format('X').valueOf() - 3600 * 6
      this.form.time1[0] = this.$moment.unix(parseInt(start)).format('YYYY-MM-DD HH:mm:ss')
      this.form.time1[1] = this.$moment(date).format('YYYY-MM-DD HH:mm:ss')
      this.form.time2[0] = this.$moment.unix(parseInt(start)).format('YYYY-MM-DD HH:mm:ss')
      this.form.time2[1] = this.$moment(date).format('YYYY-MM-DD HH:mm:ss')
      let list = ['1分钟平均值','1分钟最大值','1分钟最小值','1分钟均方根','10分钟平均值','10分钟最大值','10分钟最小值','10分钟均方根','1小时平均值','1小时最大值','1小时最小值','1小时均方根']
      this.checkList3 = []
      for (let i = 0; i < list.length; i++) {
        this.checkList3.push({value: i+1,name: list[i]})
      }
      this.getEssential()
    },
    methods:{
      getEssential(){
        this.loading = true
        this.$http.get('/sensorAnalysis/sensorType/list').then(res => {
          if (res.success){
            if (res.data){
              this.checkList1 = res.data
              this.form.sensorId = res.data[0].id
              this.getPointById(res.data[0].id)
            }
          }
        })
      },
      getPointById(id){
        this.$http.get('/sensorAnalysis/sensorDetail/list',{params:{id:id}}).then(res => {
          if (res.success){
            if (res.data){
              this.checkList2 = res.data
              this.form.pointId = res.data[0].id
              this.submit()
            }
          }
        })
      },
      submit(){
        this.submitSelect(1)
        this.submitSelect(2)
      },
      submitSelect(type){
        this['loading' + type] = true
        this.form.startTime = this.form['time' + type][0]
        this.form.endTime = this.form['time' + type][1]
        // if (this.form.type === 0){
        //   let frequency = 1
        //   for(let item of this.checkList1){
        //     if (item.id === this.form.sensorId){
        //       if (item.frequency){
        //         frequency = item.frequency
        //       }
        //       break
        //     }
        //   }
        //   this.form.startTime = this.form['time' + type][0]
        //   this.form.endTime = this.form['time' + type][1]
        //   let second = this.$moment(this.form.endTime).format('X').valueOf() - this.$moment(this.form.startTime).format('X').valueOf()
        //   if (second * frequency > 1000000){
        //     // this.$message.warning('数据量过大，请重新选择时间范围！')
        //     this.$alert('数据量过大，请重新选择时间范围', '温馨提示', {
        //       confirmButtonText: '确定',
        //       callback: () => {}
        //     });
        //     this['loading' + type] = false
        //     return
        //   }
        // }
        if (!this.form.pointId){
          this.$message.error('请选择监测点位')
          this['loading' + type] = false
          return;
        }
        this.$http.post('/sensorAnalysis/oneFactor',this.form).then(res => {
          if (res.success){
            if (res.data === null){
              this['chartData' + type] = {
                xaxis:[],
                unit:'',
                data:[]
              },
                  this.$message.warning(res.msg)
            } else {
              this['chartData' + type] = res.data
            }
          }
          this['loading' + type] = false
        })
      }
    }
  }
</script>

<style scoped>
  .an-trent {
    width: 100%;
    height: calc(100% - 40px);
    overflow-y: auto;
  }
  .an-trent::-webkit-scrollbar {
    width: 4px; /*滚动条宽度*/
    height: 4px;
  }
  /*定义滚动条轨道 内阴影+圆角*/
  .an-trent::-webkit-scrollbar-track {
    box-shadow: 0px 1px 3px #D0D0D0 inset; /*滚动条的背景区域的内阴影*/
    border-radius: 10px; /*滚动条的背景区域的圆角*/
    background-color: #D0D0D0; /*滚动条的背景颜色*/
  }
  /*定义滑块 内阴影+圆角*/
  .an-trent::-webkit-scrollbar-thumb {
    box-shadow: 0px 1px 3px #828282 inset; /*滚动条的内阴影*/
    border-radius: 10px; /*滚动条的圆角*/
    background-color: #828282; /*滚动条的背景颜色*/
  }
  .an-trent-select{
    width: 100%;
    height: auto;
    padding: 20px;
    background: #fff;
  }
  .an-title{
    width: 100%;
    height: 30px;
    line-height: 30px;
    display: flex;
    align-content: center;
    align-items: center;
    font-size: 16px;
    border-bottom: 1px solid #efefef;
    margin-bottom: 10px;
  }
  .an-title i{
    color: #1A67D0;
    font-size: 20px;
    margin-right: 5px;
  }
  .an-title span{
    color: #1A67D0;
    font-weight: bold;
  }
  .search-con{
    width: 100%;
    display: flex;
    font-size: 14px;
    padding: 10px 0 0;
  }
  .search-label{
    width: 120px;
    line-height: 40px;
    text-align: right;
    margin-right: 10px;
  }
  .search-con /deep/ .el-checkbox-group{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% - 110px);
  }
  .search-con /deep/ .el-checkbox.is-bordered{
    margin-left: 0;
    margin-right: 8px;
    padding: 6px 10px 6px 10px;
    height: auto;
    margin-bottom: 5px;
  }
  .search-con /deep/ .el-radio-group{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% - 110px);
  }
  .search-con /deep/ .el-radio.is-bordered{
    margin-left: 0;
    margin-right: 8px;
    padding: 8px 10px 8px 10px;
    height: auto;
    margin-bottom: 5px;
  }
  .an-trend-page{
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .an-trend-con{
    width: 49.5%;
    height: 100%;
    background: #fff;
  }
</style>
