<template>
  <div ref="dom" class="charts chart-scatter"></div>
</template>

<script>
  import * as echarts from "echarts";
  import * as ecStat from 'echarts-stat'
  import { on, off } from "@/libs/tools";
  export default {
    name: "ChartScatter",
    props: {
      value: Object
    },
    data() {
      return {
        dom: null,
      };
    },
    watch: {
      value: {
        handler(val) {
          this.loadEcharts(val);
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {},
    methods: {
      resize() {
        this.dom.resize();
      },
      loadEcharts(val) {
        let data = val.data
        this.$nextTick(() => {
          echarts.registerTransform(ecStat.transform.regression);
          if (this.dom != null && this.dom != "" && this.dom != undefined) {
            this.dom.dispose();
          }
          this.dom = echarts.init(this.$refs.dom);
          let option = {
            dataset: [
              {
                source: data
              },
              {
                transform: {
                  type: 'ecStat:regression'
                }
              }
            ],
            legend: {
              top: 10
            },
            grid:{
              left:120,
              right:50
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross'
              }
            },
            xAxis: {
              name:val.nameX + '(' + val.unitX + ')',
              nameLocation:'center',
              nameGap:30,
              scale:true,
              splitLine: {
                lineStyle: {
                  type: 'dashed'
                }
              }
            },
            yAxis: {
              name:val.nameY + '(' + val.unitY + ')',
              nameLocation:'center',
              nameGap:80,
              scale:true,
              splitLine: {
                lineStyle: {
                  type: 'dashed'
                }
              }
            },
            series: [
              {
                name: 'scatter',
                type: 'scatter'
              },
              {
                name: 'line',
                type: 'line',
                datasetIndex: 1,
                symbolSize: 0.1,
                symbol: 'circle',
                label: { show: true, fontSize: 16 },
                labelLayout: { dx: -20 },
                encode: { label: 2, tooltip: 1 }
              }
            ]
          };
          this.dom.setOption(option);
          on(window, "resize", this.resize);
        });
      },
    },

    beforeDestroy() {
      off(window, "resize", this.resize);
    },
  };
</script>
<style scoped>
  .charts{
    width: 100%;
    height: 100%;
  }
</style>
