<template>
  <div class="contrast-main">
    <div class="nav-list">
      <div :class="navActive === index ? 'nav-con' : 'nav-con nav-con-active'" v-for="(item,index) in navList" :key="index" @click="navClick(item,index)">
        <span>{{item.name}}</span>
      </div>
    </div>
    <module-one v-if="navActive === 0"></module-one>
    <module-two v-if="navActive === 1"></module-two>
    <module-three v-if="navActive === 2" :isExport="false"></module-three>
    <module-four v-if="navActive === 3"></module-four>
  </div>
</template>

<script>
  import ModuleOne from './components/moduleOne'
  import ModuleTwo from './components/moduleTwo'
  import ModuleThree from './components/moduleThree'
  import ModuleFour from './components/moduleFour'
  export default {
    name: "index",
    components:{ModuleOne,ModuleTwo,ModuleThree,ModuleFour},
    data(){
      return{
        navList:[
          {value:0,name:'单因素测点分析'},
          {value:1,name:'单因素多测点分析'},
          {value:2,name:'关联分析'},
          {value:3,name:'相关性分析'}
        ],
        navActive:0,
      }
    },
    methods:{
      navClick(data,i){
        this.navActive = i
      },
    }
  }
</script>

<style scoped>
  .contrast-main{
    width: 100%;
    height: 100%;
  }
  .nav-list{
    width: 100%;
    height: 40px;
    line-height: 40px;
    display: flex;
  }
  .nav-con{
    width: 160px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-left: 1px solid #1A67D0;
    border-right: 1px solid #1A67D0;
    border-top: 1px solid #1A67D0;
    color: #1A67D0;
    font-weight: bold;
  }
  .nav-con:hover{
    cursor: pointer;
  }
  .nav-con i{
    color: #1A67D0;
    margin-right: 5px;
    font-weight: bold;
    font-size: 20px;
  }
  .nav-con-active{
    color: #fff;
    background: #1A67D0;
    border-bottom: 1px solid #1A67D0;
  }
  .nav-con-active i{
    color: #fff;
  }
  /deep/ .an-trent {
    height: calc(100% - 40px);
    overflow-y: auto;
  }
  /deep/ .an-trent::-webkit-scrollbar {
    width: 4px; /*滚动条宽度*/
    height: 4px;
  }
  /*定义滚动条轨道 内阴影+圆角*/
  /deep/ .an-trent::-webkit-scrollbar-track {
    box-shadow: 0px 1px 3px #D0D0D0 inset; /*滚动条的背景区域的内阴影*/
    border-radius: 10px; /*滚动条的背景区域的圆角*/
    background-color: #D0D0D0; /*滚动条的背景颜色*/
  }
  /*定义滑块 内阴影+圆角*/
  /deep/ .an-trent::-webkit-scrollbar-thumb {
    box-shadow: 0px 1px 3px #828282 inset; /*滚动条的内阴影*/
    border-radius: 10px; /*滚动条的圆角*/
    background-color: #828282; /*滚动条的背景颜色*/
  }
</style>