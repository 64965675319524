<template>
  <div ref="dom" class="charts chart-line"></div>
</template>

<script>
  import * as echarts from "echarts";
  import { on, off } from "@/libs/tools";
  export default {
    name: "ChartLine",
    props: {
      value: Object
    },
    data() {
      return {
        dom: null,
      };
    },
    watch: {
      value: {
        handler(val) {
          this.loadEcharts(val);
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {},
    methods: {
      resize() {
        this.dom.resize();
      },
      loadEcharts(val) {
        this.$nextTick(() => {
          let option = {
            title:{
              text:val.type,
              textStyle: {
                color:'#333',
                fontSize: 16
              },
              top:20,
              left:20
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                animation: false
              }
            },
            grid:{
              top:100,
              left:70,
              bottom:40,
              right:20
            },
            toolbox: {
              right:20,
              top:20,
              feature: {
                saveAsImage: {},
                restore:{},
                dataZoom:{
                  yAxisIndex: "none"
                },
                magicType: {
                  type: ['line', 'bar']
                },
              }
            },
            legend: {
              show: true,
              type:'scroll',
              // right: 0,
              top: 20,
              left:100,
              width:'80%',
              itemGap: 20,
              itemWidth: 14,
              // orient: "vertical",
              textStyle: { fontSize: 14, color: "#333333" },
            },
            xAxis: {
              name:'',
              type: 'category',
              data: val.xaxis,
              nameGap:25,
            },
            yAxis: {
              name:val.unit,
              type: 'value',
              scale:true,
              boundaryGap: ["10%", "20%"],
              // nameLocation:'center',
              nameGap:50,
            },
            series: [{
              type: "line",
              data:val.yaxis,
              barWidth:10
            }],
          };
          if (this.dom === null){
            this.dom = echarts.init(this.$refs.dom);
          } else {
            this.dom.dispose();
            this.dom = echarts.init(this.$refs.dom);
          }
          this.dom.setOption(option);
          on(window, "resize", this.resize);
        });
      },
    },
    beforeDestroy() {
      off(window, "resize", this.resize);
    },
  };
</script>
<style scoped>
  .charts{
    width: 100%;
    height: 100%;
  }
</style>
