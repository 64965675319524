<template>
  <div class="an-trent">
    <div class="an-trent-select">
      <div class="an-title flex space-between" style="height: 50px">
        <div>
          <i class="el-icon-search"></i>
          <span>筛选条件</span>
        </div>
        <div>
          <el-button type="primary" @click="submit">查询</el-button>
          <el-button type="primary" v-if="isExport" @click="exportData">导出</el-button>
        </div>
      </div>
      <div class="search-con">
        <div class="search-label">监测点位：</div>
        <el-tabs style="width: calc(100% - 110px)">
          <el-tab-pane v-for="(sensor,s) in sensorList" :key="s" :label="sensor.name">
            <el-checkbox-group v-model="form.sensorMap[sensor.id]" @change="handleChange">
              <el-checkbox border v-for="(item,index) in sensor.children" :key="index" :label="item.id">{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </el-tab-pane>
        </el-tabs>
      </div>
<!--      <div class="search-con">-->
<!--        <div class="search-label">监测要素：</div>-->
<!--        <el-checkbox-group v-model="form.sensorId" @change="getPointById">-->
<!--          <el-checkbox border v-for="(item,index) in checkList1" :key="index" :label="item.id">{{item.name}}</el-checkbox>-->
<!--        </el-checkbox-group>-->
<!--      </div>-->
<!--      <div class="search-con">-->
<!--        <div class="search-label">监测点位：</div>-->
<!--        <el-checkbox-group v-model="form.pointId" @change="submit">-->
<!--          <el-checkbox border v-for="(item,index) in checkList2" :key="index" :label="item.id">{{item.subName}}</el-checkbox>-->
<!--        </el-checkbox-group>-->
<!--      </div>-->
      <div class="search-con">
        <div class="search-label">数据类型：</div>
        <el-radio-group v-model="form.type">
          <el-radio border v-for="(item,index) in checkList3" :key="index" :label="item.value">{{item.name}}</el-radio>
        </el-radio-group>
      </div>
      <div class="search-con" style="width: auto">
        <div class="search-label">时间范围：</div>
        <el-date-picker
            v-model="form.time1"
            size="small"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </div>

    </div>
    <div class="an-trend-page" v-loading="loading">
      <div class="an-trend-con" v-for="(chart,c) in  chartDataList" :key="c">
        <chart-three :value="chart"></chart-three>
      </div>
    </div>
  </div>
</template>

<script>
  import common from "@/assets/js/common";
  import chartThree from "../chart/chart03"
  export default {
    name: "index",
    components:{
      chartThree
    },
    props:{
      isExport:Boolean
    },
    data(){
      return{
        chartDataList:[
          // {title:'',xaxis:[], unit:'', data:[]}
        ],
        sensorList:[],
        checkList3:[],
        form:{
          sensorMap: {},
          type:1,
          time1:[],
        },
        loading:false,
        activeName:0
      }
    },
    created() {
      let date = new Date()
      let start = this.$moment(date).format('X').valueOf() - 3600 * 6
      this.form.time1[0] = this.$moment.unix(parseInt(start)).format('YYYY-MM-DD HH:mm:ss')
      this.form.time1[1] = this.$moment(date).format('YYYY-MM-DD HH:mm:ss')
      let list = ['1分钟平均值','1分钟最大值','1分钟最小值','1分钟均方根','10分钟平均值','10分钟最大值','10分钟最小值','10分钟均方根','1小时平均值','1小时最大值','1小时最小值','1小时均方根']
      this.checkList3 = []
      for (let i = 0; i < list.length; i++) {
        this.checkList3.push({value: i+1,name: list[i]})
      }
      this.getEssential()
    },
    methods:{
      handleClick(){

      },
      handleChange(){
        this.$forceUpdate()
      },
      getEssential(){
        // this.loading = true
        this.$http.get('/sensor/export/list/point').then(res => {
          if (res.success){
            if (res.data){
              this.sensorList = res.data
              this.activeName = res.data[0].id
              // this.form.sensorId = [res.data[0].id]
              // this.getPointById(res.data[0].id)
              for(let item of this.sensorList){
                // item.isIndeterminate = false
                // item.checkAll = false
                this.form.sensorMap[item.id] = []
                // if (item.children && item.children.length > 0){
                //   this.sensorDetailList = this.sensorDetailList.concat(item.children)
                // }
              }
            }
          }
        })
      },
      // getPointById(id){
      //   this.$http.get('/sensorAnalysis/sensorDetail/list',{params:{id:id}}).then(res => {
      //     if (res.success){
      //       if (res.data){
      //         this.checkList2 = res.data
      //         this.form.pointId = res.data[0].id
      //       }
      //     }
      //   })
      // },
      submit(){
        this.loading= true
        this.form.startTime = this.form.time1[0]
        this.form.endTime = this.form.time1[1]
        if (this.form.type === 0){
          let frequency = 0
          for(let item of this.sensorList){
            if (item.id === this.form.sensorId){
              if (item.frequency){
                frequency = item.frequency + frequency
              }
            }
          }
          this.form.startTime = this.form.time1[0]
          this.form.endTime = this.form.time1[1]
          let second = this.$moment(this.form.endTime).format('X').valueOf() - this.$moment(this.form.startTime).format('X').valueOf()
          if (second * frequency > 1000000){
            // this.$message.warning('数据量过大，请重新选择时间范围！')
            this.$alert('数据量过大，请重新选择时间范围', '温馨提示', {
              confirmButtonText: '确定',
              callback: () => {}
            });
            this.loading = false
            return
          }
        }
        this.$http.post('/sensorAnalysis/relevance',this.form).then(res => {
          if (res.success){
            this.chartDataList = res.data
            this.loading = false
          }
          this.loading = false
        })
      },
      exportData(){
        this.form.startTime = this.form.time1[0]
        this.form.endTime = this.form.time1[1]
        this.$http.post('/sensor/export/exportSensorData', this.form,{contentType:"application/x-www-form-urlencoded",
          responseType: "blob"}
        ).then(res => {
          common.blobDownLoad(res)
        });
      }
    }
  }
</script>

<style scoped>
  .an-trent {
    width: 100%;
  }
  .an-trent-select{
    width: 100%;
    height: auto;
    padding: 20px;
    background: #fff;
  }
  .an-title{
    width: 100%;
    height: 30px;
    line-height: 30px;
    display: flex;
    align-content: center;
    align-items: center;
    font-size: 16px;
    border-bottom: 1px solid #efefef;
    margin-bottom: 10px;
  }
  .an-title i{
    color: #1A67D0;
    font-size: 20px;
    margin-right: 5px;
  }
  .an-title span{
    color: #1A67D0;
    font-weight: bold;
  }
  .search-con{
    width: 100%;
    display: flex;
    font-size: 14px;
    padding: 10px 0 0;
  }
  .search-label{
    width: 100px;
    line-height: 40px;
    text-align: right;
    margin-right: 10px;
  }
  .search-con /deep/ .el-checkbox-group{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% - 110px);
  }
  .search-con /deep/ .el-checkbox.is-bordered{
    margin-left: 0;
    margin-right: 8px;
    padding: 6px 10px 6px 10px;
    height: auto;
    margin-bottom: 5px;
  }
  .search-con /deep/ .el-radio-group{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% - 110px);
  }
  .search-con /deep/ .el-radio.is-bordered{
    margin-left: 0;
    margin-right: 8px;
    padding: 8px 10px 8px 10px;
    height: auto;
    margin-bottom: 5px;
  }
  .an-trend-page{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .an-trend-con{
    width: 49.5%;
    height: 600px;
    background: #fff;
    margin-top: 10px;
  }
</style>
